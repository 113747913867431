<template>
    <div class="ele-container">
        <template v-if="hasExpand">
            <div style="width:100px; height: 100%;">
                <el-table :data="villageList" style="width: 100%; height: 100%;" size="small" border height="100%" stripe @row-click="selectVillage" :cell-style="tableCellStyle">
                    <el-table-column prop="v_village_name" label="小区列表" align="center">
                    </el-table-column>
                </el-table>
            </div>
            <div style="width:165px; height: 100%;">
                <el-table :data="elevatorList" style="width: 100%; height: 100%;" size="small" border height="100%" stripe @row-click="selectElevator" :cell-style="tableCellStyle">
                    <el-table-column prop="v_elevator_name" label="电梯列表" align="center">
                    </el-table-column>
                </el-table>
            </div>
            <!-- <i class="el-icon-sort ele-icon"></i> -->
        </template>
        <div class="box-switch" @click="switchBox">
            <img style="width:20px; height:auto;" :src="hasExpand?require('@/assets/ic_open.jpg'):require('@/assets/ic_close.jpg')">
        </div>
        <!--         
            <div style="width:210px; height: 100%;">
            <el-table :data="checkedElevatorList.filter(item => item != null)" style="width: 100%; height:100%;" size="small" border height="100%" stripe @row-click="delSelectElevator">
                <el-table-column prop="v_elevator_name" label="已选电梯" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-switch :width="switchWidth" v-model="hasExpand" active-color="#13ce66" inactive-color="#ff4949" active-text="已选电梯" @change="updateExpand">
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>
        </div> 
        -->
    </div>
</template>
<script>
import { getElevatorList } from '@/api/monitor/index'
import {
    searchVillageSelectList
} from '@/api/home/index.js'

export default {
    props: {
        videoCount: {
            type: Number,
            require: true
        },
        from: {
            type: String,
        }
    },
    data() {
        return {
            villageList: [],
            elevatorList: [],
            checkedElevatorList: [],
            checkedElevatorIds: [],
            currentVillageId: '',
            switchWidth: 30,
            hasExpand: true,
            apiData: {
                pageSize: 99999,
                pageIndex: 1,
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            return 'ele-checked-row';
        },
        selectElevator(row, column, event) {
            if (!this.checkedElevatorIds.includes(row.v_elevator_id)) {

                if (this.checkedElevatorIds.filter(item => item != null).length >= this.videoCount)
                    return this.$Message.info('超出当前页面布局要求视频总数')

                let idx = this.checkedElevatorIds.findIndex(item => item == null)

                if (idx > -1) {
                    this.checkedElevatorIds.splice(idx, 1, row.v_elevator_id)
                    this.checkedElevatorList.splice(idx, 1, row)
                } else {
                    this.checkedElevatorIds.push(row.v_elevator_id)
                    this.checkedElevatorList.push(row)
                }
            } else {
                let idx = this.checkedElevatorIds.findIndex(item => item == row.v_elevator_id)
                this.checkedElevatorIds.splice(idx, 1, null)
                this.checkedElevatorList.splice(idx, 1, null)
            }
        },
        selectVillage(row, column, event) {
            if (this.currentVillageId != row.v_village_id) {
                let params = this.apiData
                params["vVillageId"] = row.v_village_id
                getElevatorList(params).then(res => {
                    if (res.code === '0000') {
                        this.elevatorList = res.info.list

                        if (this.elevatorList.length > 0 && this.checkedElevatorIds.length == 0) {
                            if (this.from != 'multiple')
                                this.selectElevator(this.elevatorList[0], null, null)
                        }
                    } else {
                        // console.log("res", res)
                    }
                })

                // this.checkedElevatorIds  = []
                // this.checkedElevatorList = []
                this.currentVillageId = row.v_village_id
                this.$emit('didSelectVillage', row)
            }
        },
        delSelectElevator(row, column, event) {
            let idx = this.checkedElevatorIds.findIndex(item => item == row.v_elevator_id);
            this.checkedElevatorIds.splice(idx, 1, null);
            this.checkedElevatorList.splice(idx, 1, null);
        },
        tableCellStyle({ row, column, rowIndex, columnIndex }) {
            let res = {}

            if (this.currentVillageId == row.v_village_id) {
                res["background"] = "#007BE5!important"
                res["color"] = "#fff!important"
            }

            if (this.checkedElevatorIds.includes(row.v_elevator_id)) {
                res["background"] = "#007BE5!important"
                res["color"] = "#fff!important"
            }

            return res;
        },
        updateExpand() {
            this.$emit('onExpand', this.hasExpand)
        },
        switchBox() {
            this.hasExpand = !this.hasExpand
            this.$emit('onExpand', this.hasExpand)
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
    },
    watch: {
        checkedElevatorIds(newData, oldData) {
            let result = this.elevatorList.filter((item) => {
                return newData.indexOf(item.v_elevator_id) > -1
            })

            this.$emit("changeElevatorItems", result)
        },
        checkedElevatorList(newData, oldData) {
            this.$emit("changeElevators", newData);
        },
        videoCount(newCount, oldCount) {
            if (newCount != oldCount) {
                this.checkedElevatorIds = []
                this.checkedElevatorList = []
            }
        }
    },
    mounted() {
        let params = { ...this.apiData }
        searchVillageSelectList(this.buildParams(params)).then(res => {
            if (res.code === '0000') {
                this.villageList = res.info

                if (this.villageList.length > 0) {
                    // this.currentVillageId = this.villageList[0]['v_village_id']
                    this.selectVillage(this.villageList[0], null, null)
                }
            } else {
                console.log("res", res)
            }
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.ele-checked-row {
    background: #007BE5 !important;
    color: #fff !important;
}

.ele-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ele-icon {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    font-size: 20px;
    font-weight: 100;
}

.box-switch {
    height: 30px;
    margin-right: 0;
}

.el-table ::v-deep th.el-table__cell>.cell {
    @include font_color("tableHeaderTextColor");
}

.el-table {
    ::v-deep .el-table__cell {
        padding: 8px 0;
        @include font_color("tableCellTextColor");
        @include background_color("tableEmptyBgColor");
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color("tableEmptyBgColor");
    }
}

.el-table ::v-deep.el-table--fit {
    @include background_color(tableBgColor);
}

.el-table ::v-deep th.el-table__cell>.cell {
    @include font_color("tableHeaderTextColor");
}

.el-table {
    ::v-deep .el-table__cell {
        padding: 8px 0;
        @include font_color("tableCellTextColor");
        @include background_color("tableEmptyBgColor");
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color("tableEmptyBgColor");
    }
}

.el-table ::v-deep.el-table--fit {
    @include background_color(tableBgColor);
}

.el-table ::v-deep td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    @include border_color(panelBorderColor);
}

.el-table ::v-deep th.el-table__cell.is-leaf {
    @include border_color(panelBorderColor);
}

.el-table--border,
::v-deep .el-table--group {
    @include border_color(panelBorderColor);
}

.el-table--border::after,
::v-deep .el-table--group::after,
.el-table::before {
    @include background_color(panelBorderColor);
}


::v-deep .el-table__empty-text {
    font-size:10px;
}
</style>